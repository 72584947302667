import React from "react";
import {
  container,
  image,
  wrapper,
} from "../components/Landing/landing.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import LandingBar from "../components/LandingBar/LandingBar";
import { Helmet } from "react-helmet";

const Index = () => {
  return (
    <>
      <Helmet>
        <html lang="pl" />
        <title>#NieMówMiJak - TwojPsycholog.pl & Wioski.co</title>
        <meta
          content="#NieMówMiJak - TwojPsycholog.pl & Wioski.co"
          property="og:title"
        />
        <meta
          content="#NieMówMiJak - TwojPsycholog.pl & Wioski.co"
          property="og:site_name"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Alata&family=Inter:wght@400;500;600&display=swap"
          rel="stylesheet"
        />
        {/*<meta content="" name="description" />*/}
        {/*<meta content="" name="og:description" />*/}
        {/*<meta content={getImg()} property="og:image" />*/}
        <meta name="viewport" content="width=device-width, minimum-scale=1.0" />
      </Helmet>
      <section className={container}>
        <div className={wrapper}>
          <h1>404</h1>
          <h2>Strona nie istnieje</h2>
          <div className={image}>
            <StaticImage src="../../images/Landing.webp" alt="Landing page" />
          </div>
        </div>
      </section>
      <LandingBar />
    </>
  );
};

export default Index;
